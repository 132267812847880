@font-face {
  font-family: 'Adieu-Regular';
  src: url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Adieu-Regular.eot'); /* IE9 Compat Modes */
  src: url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Adieu-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Adieu-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Adieu-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Adieu-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Kale-Sans-Mono-Regular';
  src: url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Kale-Sans-Mono-Regular.eot'); /* IE9 Compat Modes */
  src: url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Kale-Sans-Mono-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Kale-Sans-Mono-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Kale-Sans-Mono-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/Kale-Sans-Mono-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'AllianceNo1-Regular';
  src:  url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/AllianceNo1-Regular.woff2') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0255/9429/8467/files/AllianceNo1-Regular.woff') format('woff');
}


